import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://20c3fee77c89aa6616b2d260c13f1b2f@o4506088910225408.ingest.sentry.io/4506112123666432",
  debug: false,
  environment: "production",
  release: "2024-10-26T16:04:04Z",
  tracesSampleRate: 1,
  sampleRate: 0.5,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
});